<template>
  <div class="d-flex align-center align-text-center main container">
    <el-card class="card">
      <img @click="clearAccess" src="/images/MySideline_PrimaryLogo_black.svg" />

      <h3>We’ll be back soon</h3>
      <p>The site is currently down for maintenance.</p>
      <p>
        For any inquiries, please go to
        <span
          ><a href="https://support.playrugbyleague.com/">
            https://support.playrugbyleague.com/.</a
          ></span
        >
      </p>

      <input style="border-style:none" v-model="code" />
      <br />
      <br />
      <img
        style="height:50px;"
        @click="checkAccess"
        src="/images/MySideline_PrimaryLogo_black.svg"
      />
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Maintenance",
  data() {
    return {
      code: ""
    };
  },
  methods: {
    ...mapActions("views", ["notify"]),
    clearAccess() {
      this.$store.commit("user/DEBUGGING", false);
    },
    checkAccess() {
      const code = this.$store.getters["root/maintenanceCode"];

      if (code === this.code) {
        this.$store.commit("user/DEBUGGING", true);
        this.notify({
          msg: "Access granted, feel free to navigate",
          route: this.$route.name,
          type: "warning",
          window
        });
      } else {
        this.notify({
          msg: "Your access code is incorrect. Please try again.",
          route: this.$route.name,
          type: "warning",
          window
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  max-width: 500px;
}
.card {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;

  img {
    width: 70%;
  }
}
</style>
